<template>
    <div>
        <footer class="main-footer">
            <div class="pattern-layer" :style="{backgroundImage: 'url('+ pattern4 + ')'}"></div>
            <div class="left-pattern-image">
                <img src="../../assets/images/background/footer-pattern-1.png" alt="" />
            </div>
            <div class="right-pattern-image">
                <img src="../../assets/images/background/footer-pattern-2.png" alt="" />
            </div>
            <div class="auto-container">
                <!-- Widgets Section -->
                <div class="widgets-section">
                    <div class="row clearfix">

                        <!-- Footer Column -->
                        <div class="footer-column col-lg-6 col-md-6 col-sm-12">
                            <div class="footer-widget logo-widget">
                                <div class="logo">
                                    <a href="javascript:void(0)"><img src="../../assets/images/logo.png" alt="" /></a>
                                </div>
                                <div class="text">
                                    We are everything a traditional financial institution is not. We set out to give investors better, simpler and more profitable ways to become financially successful and secure.
                                </div>
                                <!-- Social Box -->
                                <ul class="social-box">
                                    <li><a href="javascript:void(0)" class="fab fa-telegram"></a></li>
                                    <li><a href="https://wa.me/+12136312284" target="_blank" class="fab fa-whatsapp"></a></li>
                                    <li><a href="mailto:trustearnss@gmail.com" class="fa fa-envelope"></a></li>
                                    <!--                                <li><a href="javascript:void(0)" class="fab fa-instagram"></a></li>-->
                                    <!--                                <li><a href="javascript:void(0)" class="fab fa-youtube"></a></li>-->
                                </ul>
                            </div>
                        </div>

                        <!-- Footer Column -->
                        <div class="footer-column col-lg-6 col-md-6 col-sm-12">
                            <div class="footer-widget info-widget">
                                <h4>Contact Now</h4>
                                <ul class="footer-list">
<!--                                    <li><span>Fex:</span><a href="tel:+555-111-999">555-111-999</a></li>-->
                                    <li><span>Phone:</span><a href="tel:+12136312284">+1-(213)-631‑2284</a></li>
                                    <li><span>Email:</span><a href="mailto:trustearnss@gmail.com">trustearnss@gmail.com</a></li>
                                    <li><span>Location:</span>23rd Avenue, Fresno California,U.S</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="widgets-section">
                    <div class="row clearfix">
                        <div class="col-12">
                            <h4>RISK DISCLAIMER</h4>
                            <p class="mt-3">
                                Nothing contained in this website should be construed as a solicitation or offer,
                                or recommendation, to acquire or dispose of any investment or to engage in any other
                                transaction. The information provided herein is not intended for distribution to, or use by,
                                any person or entity in any jurisdiction or country where such distribution or use would be
                                contrary to law or regulation. Some services may not be available to certain investors due to
                                regulatory or other constraints either in Switzerland, the United States or elsewhere. It is
                                advised that most services are only available following completion of the Customer Agreement
                                and/or any other relevant documentation as required. Investments in securities or financial
                                instruments (including digital currencies, futures, options, contracts for differences, spot
                                and forward foreign exchange contracts) can fluctuate in value. Accordingly, you should be aware
                                that you might not realise the initial amount invested and indeed may incur additional
                                liabilities as investments in securities or financial instruments may entail above average risk.
                                You must therefore carefully consider whether your financial circumstances permit you to invest.
                                TrustEarn strongly suggests that you seek the advice of an independent financial advisor in
                                this regard.
                            </p>
                        </div>
                        <div class="col-12">
                            <img src="../../assets/images/tfc-icon.png" alt="">
                            <p class="mt-3">
                                Trust Earn  is a member of The Financial Commission, an international organization
                                engaged in the resolution of disputes within the financial services industry in the Forex market.
                            </p>
                        </div>
                        <div class="col-12">
                            <img src="../../assets/images/sec-logo.png" alt="">
                            <p class="mt-3">
                                TrustEarn is regulated by the US Securities and Exchange Commission.
                                The mission of the SEC is to protect investors; maintain fair, orderly, and
                                efficient markets; and facilitate capital formation. TrustEarn is an investment company
                                that . Direct access to over 50 financial markets through one account. Any information
                                contained on this website is provided to you for informational purposes only and should
                                not be regarded as an offer or solicitation of an offer to buy or sell any investments or
                                related services that may be referenced here. Investing in certain instruments, including
                                stocks, options, futures, foreign currencies, and bonds involve a high level of risk.
                                Trading on margin comes with substantial risk as well. You must be aware of these risks
                                before opening an account to trade. The income you may get from online investing may go
                                down as well as up. TrustEarn is a registered investment dealer, a member of the United
                                States Securities and Exchange Commission (SEC), the Investment Industry Regulatory
                                Organization of Canada (IIROC) and a member of the Canadian Investor Protection Fund (CIPF),
                                the benefits of which are limited to the activities undertaken by TrustEarn.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Footer Bottom -->
                <div class="footer-bottom">
                    <div class="clearfix">
                        <div class="pull-left">
                            <div class="copyright">© Copyright 2018 - {{(()=>new Date().getFullYear())()}} <a href="javascript:void(0)">TrustEarns.</a> All Rights Reserved.</div>
                        </div>
                        <div class="pull-right">
                            <ul class="footer-nav">
                                <li>
                                    <router-link :to="{name:'Legal'}">
                                        Legal
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name:'PrivacyPolicy'}">
                                        Privacy Policy
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    </div>
</template>

<script>
import pattern4 from "../../assets/images/background/pattern-4.png";

export default {
    name: "footNav",
    data(){
        return {
            pattern4
        }
    },
    methods: {
        dd(){

        }
    }
}
</script>

<style scoped>

</style>